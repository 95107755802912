import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectLogin } from "../../slices/loginSlice";
import { Button, Text, Avatar } from "react-native-paper";
import { loginWeb, logout } from "../../lib/login/auth.web";

type Props = {};

const SignIn = (props: Props) => {
  const userState = useSelector(selectLogin);
  
  const handleLogin = useCallback(() => {
    loginWeb();
  }, []);

  const handleLogout = useCallback(() => {
    logout();
  }, []);

  return !userState ? (
    <Button mode="contained-tonal" onPress={handleLogin}>
      Sign In with Google
    </Button>
  ) : (
    <Button mode="outlined" onPress={handleLogout}>
      Logout
    </Button>
  );
};

export default SignIn;
