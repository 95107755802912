import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../lib/firebase/firebase.web";
import { consoleLog } from "./helpers";

export const uploadRecentRecommendation = async (
  uid: string,
  recommendation: any
) => {
  try {
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef);
    const timestamp = Timestamp.now();
    const date = timestamp.toDate().toISOString().slice(0, 10);
    consoleLog("uploading recent recommendation", recommendation);
    // Create a new recommendation object with the timestamp
    const recommendationWithTimestamp = {
      artist: recommendation.artist || "",
      song: recommendation.song || "",
      genre: recommendation.genre || "",
      mood: recommendation.mood || "",
      albumArt: recommendation.albumArt || "",
      timestamp: timestamp.toDate().toISOString(),
      songMeta: recommendation.songMeta || {},
      songLinks: recommendation.songLinks || "",
      theme: recommendation.theme || "",
    };

    if (docSnap.exists()) {
      const currentRecents = docSnap.data()?.recents || {};
      const dateRecents = currentRecents[date] || [];

      await setDoc(
        userRef,
        {
          recents: {
            ...currentRecents,
            [date]: [...dateRecents, recommendationWithTimestamp],
          },
        },
        { merge: true }
      );
    } else {
      await setDoc(userRef, {
        recents: {
          [date]: [recommendationWithTimestamp],
        },
      });
    }
  } catch (error) {
    console.error("Error uploading recommendation:", error);
    throw error;
  }
};

export const getRecents = async (uid: string) => {
  try {
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const recents = docSnap.data()?.recents;
      consoleLog("recents", recents);
      return recents;
    } else {
      consoleLog("no recents");
      return null;
    }
  } catch (error) {
    console.error("Error getting recents:", error);
    return null;
  }
};
