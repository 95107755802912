import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Dimensions,
  Modal,
} from "react-native";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecentRecommendations,
  selectRecentLoading,
  selectSortedRecents,
} from "../../slices/musicSlice";
import {
  Chip,
  Text,
  ActivityIndicator,
  withTheme,
  Button,
} from "react-native-paper";
import { Helmet } from "react-helmet";
import { StandardSeo } from "../../constants/seo";
import { selectLogin } from "../../slices/loginSlice";

import SongLinks from "../recommendation/songLinks";

interface Recommendation {
  artist: string;
  genre: string;
  mood: string;
  song: string;
  timestamp: string;
  albumArt: string;
  songLinks: any;
  songMeta?: {
    spotify?: string;
    youtube?: string;
  };
}

interface RecentsByDate {
  [date: string]: Recommendation[];
}

interface Props {
  theme: {
    colors: {
      primary: string;
      surface: string;
    };
  };
  navigation: any;
}

const Recents = ({ theme, navigation }: Props) => {
  const userstate = useSelector(selectLogin);
  const loading = useSelector(selectRecentLoading);
  const dispatch = useDispatch();
  const recents: RecentsByDate = useSelector(selectSortedRecents);
  const screenWidth = Dimensions.get("window").width;
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<Recommendation | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchRecents = async () => {
      if (userstate && mounted) {
        try {
          await dispatch(getRecentRecommendations());
        } catch (error) {
          console.error("Failed to fetch recent recommendations:", error);
        }
      }
    };

    fetchRecents();

    return () => {
      mounted = false;
    };
  }, [userstate, navigation, dispatch]);

  const formatDate = (dateString: string) => {
    try {
      return new Date(dateString).toLocaleDateString("en-GB", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  const handleRecommendationPress = (recommendation: Recommendation) => {
    setSelectedRecommendation(recommendation);
    setModalVisible(true);
  };

  const renderRecommendation = (
    recommendation: Recommendation,
    index: number
  ) => {
    const { artist, song, albumArt } = recommendation;

    return (
      <View key={`${song}-${artist}-${index}`}>
        <Chip
          style={{
            margin: 4,
            width: screenWidth - 32,
            maxWidth: 900,
          }}
          onPress={() => handleRecommendationPress(recommendation)}
          avatar={
            albumArt ? (
              <Image
                source={{ uri: albumArt }}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 12,
                  marginVertical: 4,
                }}
              />
            ) : null
          }
        >
          {artist && song ? `${artist} - ${song}` : "Unknown Track"}
        </Chip>
      </View>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <ActivityIndicator
          animating={true}
          color={theme.colors.primary}
          style={{ marginBottom: 16 }}
        />
      );
    }

    if (!userstate) {
      return (
        <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            marginVertical: 16,
          }}
        >
          Login to see your recommendations!
        </Text>
      );
    }

    if (Object.values(recents).length === 0) {
      return (
        <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            marginVertical: 16,
          }}
        >
          No recommendations yet!
        </Text>
      );
    }

    return Object.entries(recents).map(([date, recommendations], index) => (
      <View
        key={`${date}-${index}`}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Chip style={{ marginVertical: 16 }} mode="outlined">
          <Text style={{ fontSize: 16, fontWeight: "700" }}>
            {formatDate(date)}
          </Text>
        </Chip>

        {Array.isArray(recommendations) &&
          recommendations.map((recommendation, idx) =>
            renderRecommendation(recommendation, idx)
          )}
      </View>
    ));
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Helmet>
        <title>Recents | DjGPT</title>
      </Helmet>
      <StandardSeo title="Recents | DjGPT" />

      <Text style={styles.title}>Recent recommendations</Text>

      {renderContent()}

      <Modal
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        animationType="fade"
        transparent={true}
      >
        <View style={styles.modalContainer}>
          <View
            style={[
              styles.modalContent,
              { backgroundColor: theme.colors.surface },
            ]}
          >
            {selectedRecommendation && (
              <>
                {selectedRecommendation.albumArt && (
                  <Image
                    source={{ uri: selectedRecommendation.albumArt }}
                    style={styles.modalImage}
                  />
                )}
                <Text style={styles.subtitle}>
                  {selectedRecommendation.artist} -{" "}
                  {selectedRecommendation.song}
                </Text>
                <Text style={styles.text}>
                  Added:{" "}
                  {new Date(selectedRecommendation.timestamp).toLocaleString()}
                </Text>

                {selectedRecommendation.songLinks && (
                  <View style={styles.linksContainer}>
                    <SongLinks songLinks={selectedRecommendation.songLinks} />
                  </View>
                )}

                <Button
                  mode="contained"
                  onPress={() => setModalVisible(false)}
                  style={{ marginTop: 16 }}
                >
                  Close
                </Button>
              </>
            )}
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
};

export default withTheme(Recents);

const styles = StyleSheet.create({
  container: {
    maxWidth: 900,
    marginHorizontal: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
    width: "100%",
  },
  image: {
    width: "100%",
    height: 300,
    objectFit: "cover",
  },
  title: {
    fontSize: 24,
    fontWeight: "900",
    marginBottom: 16,
    textAlign: "center",
  },
  date: {
    textTransform: "uppercase",
    fontWeight: "700",
    marginHorizontal: "auto",
    fontSize: 16,
    marginVertical: 16,
  },
  subtitle: {
    fontWeight: "700",
    marginBottom: 16,
    fontSize: 18,
  },
  text: {
    fontSize: 16,
    marginBottom: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: 20,
  },
  modalContent: {
    padding: 20,
    borderRadius: 10,
    width: "100%",
    maxWidth: 500,
    alignItems: "center",
  },
  modalImage: {
    width: 200,
    height: 200,
    borderRadius: 10,
    marginBottom: 16,
  },
  linksContainer: {
    width: "100%",
    gap: 8,
    marginTop: 8,
  },
  linkButton: {
    marginVertical: 4,
  },
});
