import {
  getRecommendation,
  selectGenre,
  selectInputtedSong,
  selectLoading,
  selectMood,
  selectRecommendations,
} from "../../slices/musicSlice";
import React from "react";
import { Button } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { logEvent } from "../../utils/analytics";

type RecommendationProps = {};

const Recommendation: React.FC<RecommendationProps> = ({}) => {
  const allRecommendations = useSelector(selectRecommendations);
  const selectedGenre = useSelector(selectGenre);
  const selectedMood = useSelector(selectMood);
  const loadingState = useSelector(selectLoading);
  const inputtedSong = useSelector(selectInputtedSong);
  const dispatch = useDispatch();
  const handleRecommendation = () => {
    dispatch(
      getRecommendation({
        genre: selectedGenre,
        mood: selectedMood,
        song: inputtedSong,
        previousSongs: allRecommendations,
      })
    );
    logEvent("recommendation", {
      genre: selectedGenre,
      mood: selectedMood,
      song: inputtedSong,
      previousSongs: allRecommendations,
    });
  };
  return (
    <Button
      icon="music-note"
      mode="contained-tonal"
      uppercase
      onPress={handleRecommendation}
      style={{ borderRadius: 16, width: "100%", marginBottom: 16 }}
      contentStyle={{ paddingVertical: 8 }}
      loading={loadingState}
      disabled={loadingState}
    >
      {loadingState ? "Thinking..." : "Get Recommendation"}
    </Button>
  );
};

export default Recommendation;
