import { configureStore } from "@reduxjs/toolkit";
import musicReducer from "./slices/musicSlice";
import loginReducer from "./slices/loginSlice";
import navReducer from "./slices/navSlice";

export const store = configureStore({
  reducer: {
    music: musicReducer,
    login: loginReducer,
    nav: navReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Disable serializable check middleware in development to improve performance
      serializableCheck: false,
      thunk: {
        extraArgument: "getRecommendation",
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
